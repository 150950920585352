@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    width: 100%;
    height: 100%;
}

.guide-lines {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.guide-lines-ruler {
    position: absolute;
    top: 0;
    left: 0;
}

.guide-lines-ruler.guide-lines-horizontal {
    left: 0;
    width: 100%;
    height: 30px;
}

.guide-lines-ruler.guide-lines-vertical {
    top: 0;
    width: 30px;
    height: 100%;
}

.canvas-box {
    /*position: relative;*/
    width: 100%;
    height: 100%;
}

.inside-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 9px 2px #0000001f;
    z-index: 2;
    pointer-events: none;
}


#canvas {
    width: 300px;
    height: 300px;
    margin: 0 auto;
}

#workspace {
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
    background: #f1f1f1;
    overflow: hidden;
}
